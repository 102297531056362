
* {
    font-family: 'Outfit', sans-serif!important;
    line-height: 1.8rem;
}
.cursor{
    cursor: pointer;
}
.site_bg{
    background-color: #7E277C!important;
}
.btn-bg {
    background-color: #FFFF00!important;
}
.site_txt{
    color: #7E277C;
}
.nav-bar span{
    font-size: 13px;
    font-weight: 400;
}
.nav-bar span:hover{
    color: #7E277C!important;
    opacity: 1!important;
}
.nav-bar .active span{
    color: #7E277C!important;
    opacity: 1!important;
    border-bottom: 2px solid #7E277C;
}

/* footer */

.footer{
    background-color: #FFEFFE;
}
.footer .div1{
    font-size: 15px;
}
.footer .div2{
    font-size: 16px;
}
.footer .location{
    width: 15px;
}
.footer .logo{
    width: 149px;
}

/* home */

.carousel-indicators li {
    display: none;
}

.section1 .center-content h1{
    font-size: 60px;
}
.section1  .carousel-item {
    height: 100vh; 
    display: flex;
    align-items: center;
    justify-content: center;
}
.section1  h1{
   font-size: 55px;
}
.section1  h5{
    font-size: 30px;
 }
.section1  .carousel-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 1;
    height: 750px;
}
.carousel-caption {
    z-index: 2;  
    top: 10%;
}
.carousel-caption .card{
    background-color: #00000033;
    transform: scale(1); 
    transition: transform 1s ease;
}
.carousel-caption .card img {
    transition: transform 1s ease;
    transform: scale(1); 
}
.carousel-caption .card:hover img {
    transform: scale(1.3); 
}
.carousel-caption .card:hover{
    background-color: #7E277C;
    transform: scale(1.1); 

}
   
.carousel-item {
    position: relative;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    opacity: 0; 
    transition: opacity 12s ease-in-out; 
}
  
.carousel-item.active {
    opacity: 1; 
}
  
.carousel-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); 
    z-index: 1;
    transition: opacity 12s ease-in-out; 
}
.carousel-item.active .carousel-overlay {
    opacity: 0.7; 
}
  











.pxx-5{
    padding-left: 90px!important;
    padding-right: 90px!important;
}
.section2 img{
    width: 90%;
}
.section2 span{
    font-size: 20px;
    line-height: 2rem;
    font-weight: 100;
    text-align: justify;
    display: flex;
}
.foo-cnt{
    display: flex;
    text-align: justify;
}
.section2 .col-7 ,.section2 .col-1{
    border-top: 5px solid #FFE8FE;
}
.section2 .col-4{
    border-bottom: 6px solid #FFE8FE;
}
.section2 button:hover{
    background-color: #fff;
    color: rgb(165, 163, 163)!important;
    transition: background-color 0.9s ease;
}
.section2 button:hover a{
    color: rgb(165, 163, 163)!important;
}
.section3{
    background: linear-gradient(90deg, #FFFFFF, #F8F8F8);
}
.section3 h1{
    font-size: 50px;
    font-weight: 500;
}
.section3 p{
    font-size: 18px;
    font-weight: normal;
    line-height: 32px;
    text-align:justify;
    display: flex;
}
.section3 h6{
    font-size: 18px;
    font-weight: normal;
    line-height: 2rem;
}
.section4 h2{
    font-weight: 400;
}
.section4 h5{
    font-weight: 100;
    line-height: 2rem;
}
.section4 .card1 {
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.section4 .bg-img-inner {
    background-image: url('../public/1.svg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    padding-top: 55%; 
    transition: transform 0.8s ease;
}

.section4 .card1:hover .bg-img-inner {
    transform: scale(1.1);
}

.section4 .card-body {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    padding: 0;
    opacity: 0;
    transition: opacity 0.5s ease;
}

.section4 .card1:hover .card-body {
    opacity: 1;
}
.section4 .card-overlay {
    background: rgba(0, 0, 0, 0.6);
    height: 100%;
    width: 100%;
}

.section4 .arw-img {
    width: 68px;
}
.secondary-img{
    width: 30px;
    height: 30px;
}
.section5 .card{
    width: 85%!important;
    background-color: #F8F8F8;
    box-shadow: 0px 8px 24px 0px #0000001A;
    border: 0;
    max-height: 100%;
    min-height: 300px; 
    display: flex;
    flex-direction: column;
}
/* Increase dot size */
.slick-dots li button:before {
    font-size: 16px!important; /* Increase the size as needed */
}

/* Optionally, customize the active dot color */
.slick-dots li.slick-active button:before {
    color: #7A1B82!important; 
    font-size: 26px!important;/* Change color as needed */
}
.slick-list{
    padding-bottom: 34px!important;
}
.slick-dots li button:hover, .slick-dots li button:focus{
    color: #6c757d!important;  
}
/* aboutus */

.abt-banner img{
    width: 100%;
    height: 300px;
    object-fit: cover;
}
.abt-banner .carousel-item {
    position: relative;
}
.abt-banner .carousel-item::before {
    content: "ABOUT US";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Adjust opacity as needed */
    color: white;
    font-size: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: bold;
    z-index: 1;
}
.abt-banner .carousel-item img {
    position: relative;
    z-index: 0;
}
.about-us .p-5{
    padding: 4rem !important;
}
.about-us .card {
    background-image: url('../public/bg-frame.svg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.about-us .card p{
    font-weight: normal;
    font-size: 17px;
    text-align: justify;
}
.abt-top{
    text-align: justify;
  
}
/* contactus */
.carousel-item {
    transition: opacity 20s ease-in-out; /* Adjust transition duration as needed */
}
.contact-banner img{
    object-fit: cover;
    height: 300px;
    width: 100%;
}
.contact-banner .carousel-item {
    position: relative;
}
.contact-banner .carousel-item::before {
    content: "CONTACT US";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    font-size: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: bold;
    z-index: 1;
}
.contact-banner .carousel-item img {
    position: relative;
    z-index: 0;
}
.form-bg{
    background-image: url('../public/bg-frame.svg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

/* form */
form lable{
 color: #7E277C;
}
.select-with-arrow {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    padding-right: 30px; 
    background-image: url('../public/arrow.svg');
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 15px;
}
.dial_code {
    align-self: center;
    border: 1px solid rgb(221, 220, 220);
    padding: .375rem 0rem .375rem .75rem;
    border-radius: 5px;
    border-right: unset;
    border-bottom-right-radius: unset;
    border-top-right-radius: unset;
}
form .rounded-end{
    --bs-border-radius: 0rem!important;
}
.placeholder-option {
    color: #6c757d!important; 
}
form .form-control::placeholder{
    color: #808080!important;
    font-weight: normal; 
}
form .form-control:focus{
    box-shadow:none!important;
    border-color:#d4d3d3!important;
}

/* product */
 
.product-banner .carousel-image {
    width: -webkit-fill-available;
    height: 300px;
    transition: opacity 2s ease-in-out; /* Smooth fade in/out */
    opacity: 1;
}

.product-banner .carousel-image.fade {
    opacity: 0; /* Fades out when 'fade' class is added */
}

.product-banner .image-overlay {
    position: relative;
    overflow: hidden;
}

.product-banner .black-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.39); /* Black shade overlay with opacity */
    z-index: 1;
}

.product-banner .text-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    text-align: center;
    z-index: 2;
}


.product .top p{
    font-size: 18px;
}
.product .main-div .card{
    box-shadow: 0px 16px 24px 0px #0000001A;
}
 
.bottom-card{
    width: 200px;
}
.first-modal .modal{
    --bs-modal-width: 829px!important;
}
.second-modal .modal{
    --bs-modal-width: 590px!important;
}
#exampleModalToggle .buttons{
    padding-left: 100px;
    padding-right: 100px;
}
.second-modal .card{
    border: 0;
}
.second-modal .card{
    padding-top: 10px!important;
}
.subcategory-dot {
    /* max-height: 53px; */
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    min-height: 86px;   
}
.testi-dots{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    min-height: 100px;    
}
.btn-close:focus {
    box-shadow:none!important;
}
/* marquee div container */
.section6 {
    overflow: hidden;
}

.marquee {
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: hidden;
    width: 100%;
}

.marquee-content {
    display: flex;
    animation: scroll 10s linear infinite;
}

.marquee-content img {
    width: 200px; /* Adjust based on your design */
    margin-right: 20px;
    margin-left: 20px;
}

@keyframes scroll {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-100%);
    }
}
.product-card{
    height: 210px;
}
.mbl-view{
    display: none;
}
.sys-view{
    display: block;
}
.sm-size{
    display: block;
}
.smm-size{
    display: none;
}
.fixed-tops{
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 111;
    background-color: #fff;
    animation: slideDown 0.8s ease-out forwards!important;
    padding-left: 107px!important;
    padding-right: 107px!important;
}
@keyframes slideDown {
    0% {
        transform: translateY(-100%);
    }
    100% {
        transform: translateY(0);
    }
}
#scroll-to-top {
    display: none;
    position: fixed;
    bottom: 10px;
    right: 15px;
    z-index: 99;
    cursor: pointer;
}
.hme-icon{
    width: 55px;
    height: 55px;
}
.form-fs{
    font-size: 13px;
}
.dropdown-submenu .dropdown-menu {
    left: 100%;
    top: 0;
    margin-left: 0.1rem;
    margin-top: 30px;
}
.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 1;
}
.first-menu-li {
    margin-left: -25px!important;
}
.dropdown-item{
    font-size: 14px;
}
.dropdown-menu{
    background-color: #FFF4FE!important;
}
.dropdown-item:focus, .dropdown-item:hover {
    background-color: #FFF4FE!important;
    color: #000!important;
}
.btn-group:focus-visible{
    border: none!important;
    outline: none !important;
}
.btn-sm{
color: rgb(112, 112, 112)!important;
}
.btn-sm:hover{
    color: #7E277C!important;
}
.social-media img{
  width: 30px;
}
.top-title{
    font-weight: normal;
}
.dropdown-toggle::after{
    vertical-align: 0.1em!important;
    margin-left: 0.399em!important;
    transform: rotate(-89deg);
}
@media only screen and (max-width: 600px) {
    .mbl-view{
        display: block!important;
    }
    .sys-view{
        display: none!important;
    } 
    .top-head span{
        font-size: 14px;
    }
    .logo{
        width: 69px!important;
    }
    .section2 span {
        font-size: 17px!important;
    }
    .section2 .col-xl-7{
        /* margin-top: -78px; */
    }
    .section2 img{
        width: 30%!important;
        margin-right: -48px;
    }
    .section3 p {
        font-size: 17px!important;
    }
    .section4 h5 {
        font-size: 17px!important;
    }
    .slick-dots{
        display: none!important;
    }
    /* .slick-slide {
        width: 340px!important;
    } */
    .fixed-tops {
        padding-left: 22px !important;
        padding-right: 12px !important;
    }
    .bottom-card {
        width: 140px!important;
    }
    .bottom-card img{
        width: 26px!important;
    }
    .section1 h1 {
        font-size: 24px!important;
    }
    .section1 h5 {
        font-size: 16px!important;
    }
    .carousel-caption {
        right: 7%!important;
        left: 7%!important;
    }
    .carousel-caption {
        top:5%!important;
    }
    .hme-icon{
        width: 40%!important;
    }
    .section1 .carousel-background {
        height: 542px!important;
    }
    .carousel-caption .card {
        background-color: #7E277C!important;
    }
    .section4 .bg-img-inner {
        height: 159px !important;
        width: 272px !important;
    }

    .section4  .card-overlay .d-flex{
        padding-top: 40px;
        justify-content: center;
    }
    .section4  .card-overlay .d-flex h3{
        font-size: 15px!important; 
    }
    .section4 .icon-img{
        width: 49px!important;
    }
    .section4 .card-body {
        opacity: 1!important;
    }
    .top-title{
        font-size: 12px; 
    }
    .copy-cnt{
        font-size: 14px; 
    }
    .next-prev-btn{
        font-size: 14px; 
    }

}
@media only screen and (max-width: 767px) {
    .sm-size{
        display: none!important;
    }
    .smm-size{
        display: block!important;
    }
    .section3 h1 {
        font-size: 36px!important;
    }
    .marquee-content img {
        width: 156px!important;
    }
}
@media (min-width: 601px) and (max-width: 767px){
    .container, .container-sm {
        max-width: 679px!important;
    }
    .nav-bar span {
        font-size: 12px!important;
    }
    .first-menu-li {
        margin-left: -142px !important;
    }
}
@media (min-width: 600px) and (max-width: 1024px){
    .fixed-tops {
        padding-left: 16px !important;
        padding-right: 11px !important;
    }
    .section1 h1 {
        font-size: 35px!important;
    }
    .section1 h5 {
        font-size: 20px!important;
    }
    .carousel-caption {
        right: 11%!important;
        left: 11%!important;
    }
    .section4 .arw-img {
        width: 48px!important;
    }
    .section4 .icon-img{
        width: 48px;
    }
    .front-dot {
        /* max-height: 53px; */
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        min-height: 50px;
    }
    .top-title{
        font-size: 12px; 
    }
    .section4  .card-overlay .d-flex h3{
        font-size: 17px!important; 
    }
    .copy-cnt{
        font-size: 14px; 
    }
}
@media (min-width: 768px) and (max-width: 1000px){
    .product-card {
        height: 173px!important;
    }
    .first-menu-li {
        margin-left: -112px !important;
    }
}